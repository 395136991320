import { useNavigate } from "react-router-dom";

export const useContactNavigation = () => {
  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return navigateToContact;
};
