export const validateForm = (name, email, subject, message) => {
  let formErrors = {};
  if (!name) formErrors.name = "Name is required";
  if (!email) {
    formErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    formErrors.email = "Email address is invalid";
  }
  if (!subject) formErrors.subject = "Subject is required";
  if (!message) formErrors.message = "Message is required";
  return { isValid: Object.keys(formErrors).length === 0, errors: formErrors };
};
