import React from "react";

const Footer = () => {
  return (
    <footer className="bg-custom-beige w-full p-10 lg:p-24">
      <div className="grid grid-cols-1 py-4 lg:grid-cols-3 gap-12 lg:gap-4 ">
        <div>
          <h3 className="text-4xl font-bold text-custom-pink mb-8 lg:mb-14">
            Get in touch
          </h3>
          <div className="flex items-center mb-8 lg:mb-12 text-xl">
            <i className="material-icons-round text-custom-pink mr-2">email</i>
            <a
              href="mailto:info@gb-wholesale.com"
              className="text-custom-grey hover:text-custom-pink"
            >
              info@gb-wholesale.com
            </a>
          </div>
          <div className="flex text-xl">
            <i className="material-icons-round text-custom-pink mr-2">place</i>
            <span className="text-custom-grey">
              182-184 High Street North,<br></br>London, E6 2JA
            </span>
          </div>
        </div>
        <div>
          <h3 className="text-4xl font-bold text-custom-pink mb-8 lg:mb-14">
            Popular Links
          </h3>
          <ul className="space-y-5 text-xl text-custom-grey">
            <li>
              <a href="/about" className="hover:text-custom-pink">
                &gt; About us
              </a>
            </li>
            <li>
              <a href="/services" className="hover:text-custom-pink">
                &gt; Services
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:text-custom-pink">
                &gt; Contact us
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-4xl font-bold text-custom-pink mb-8 lg:mb-14">
            GB Wholesale
          </h3>
          <p className="text-xl text-custom-grey">
            We look forward to hearing from you and exploring how GB Wholesale
            can transform your connectivity and empower your success in
            the digital era. Thank you for considering us as your trusted
            partner in networking solutions.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
