import React from "react";
import Form from "../components/contact/Form";
import ContactInfo from "../components/contact/ContactInfo";

const Contact = () => {
  return (
    <div className="bg-custom-beige flex lg:justify-center items-center">
      <div className="p-16 inline-flex flex-col items-center lg:items-start w-auto">
        <h1 className="text-6xl lg:text-8xl mb-16 w-full ">Contact</h1>
        <div className="w-auto lg:flex lg:justify-center lg:space-x-72">
          <div>
            <ContactInfo />
          </div>
          <div className="lg:max-w-md">
            <h2 className="text-3xl mt-16 lg:mt-0 mb-8">Write to us</h2>
            <p className="mb-4">
              Whether you're seeking more information about our services, are
              interested in a consultation, or have specific inquiries, our team
              is ready to assist you. Please fill out the form below, and we'll
              get back to you promptly.
            </p>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
