import React from "react";
import image from "../../assets/blobs/bannerblobimage.png";
import blob3 from "../../assets/blobs/bannerblob3.svg";
import blob1 from "../../assets/blobs/bannerblob1.svg";
import blob2 from "../../assets/blobs/bannerblob2.svg";

const HomeBanner = () => {
  return (
    <div className="flex h-[850px] justify-center overflow-hidden">
      <div className="w-2/6 flex justify-center">
        <img src={image} className="absolute w-[800px] max-w-[150%]" />
        <img
          src={blob3}
          className="transform rotate-180 -translate-x-3/4 translate-y-1/4 w-7/12"
        />
      </div>
      <div className="w-1/5 flex flex-col mt-32 ml-48">
        <h2 className="text-3xl text-custom-pink mb-6">GB Wholesale</h2>
        <h1 className="text-8xl text-custom-grey mb-8">
          Bridging Horiz<span className="underline-pink">ons</span>, Powering
          <span className="underline-orange"> Co</span>nnections
        </h1>
        <h4 className="text-lg text-custom-grey font-roboto-regular">
          Unleashing the Pulse of the Digital Realm
        </h4>
      </div>
      <div className="flex-1 flex flex-col justify-end relative">
        <img
          src={blob1}
          className="transform rotate-[180deg] absolute -translate-y-44 translate-x-[430px] w-3/6"
        />
        <img
          src={blob2}
          className="transform rotate-[210deg] translate-x-36 translate-y-1/4 w-full"
        />
      </div>
    </div>
  );
};

export default HomeBanner;
