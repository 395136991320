import React from "react";
import image from "../../assets/images/cables.png";
import image2 from "../../assets/images/hmmpoints.png";
import blob1 from "../../assets/blobs/bannerblob1.svg";
import waves from "../../assets/waves/waves.svg";

const ServicesBanner = () => {
  return (
    <div className="relative flex flex-col h-[926px] justify-center overflow-hidden">
      <div className="flex w-full justify-end">
        <div className="w-1/3 flex flex-col justify-center mb-56">
          <h2 className="text-3xl text-custom-pink mb-6">
            Elevating Connectivity<br></br> Beyond Boundaries
          </h2>
          <h1 className="text-8xl text-custom-grey mb-20">Services</h1>
          <p>
            At GB Wholesale, our services are designed to revolutionise your
            connectivity experience. Whether you're seeking a robust backbone
            infrastructure, global connectivity solutions, or personalised IP
            address leasing services, we have the expertise and innovation to
            meet your unique requirements.
          </p>
        </div>
        <div className="w-1/2 flex flex-col justify-center relative mb-36">
          <img
            src={blob1}
            className="transform rotate-[210deg] absolute -translate-y-10 translate-x-44 w-5/6"
          />
          <img
            src={image}
            className="absolute translate-x-96 -translate-y-28 w-5/12"
          />
          <img
            src={image2}
            className="absolute translate-x-36 translate-y-36 w-5/12"
          />
        </div>
      </div>
      <img src={waves} className="absolute bottom-0 left-0 w-full" />
    </div>
  );
};

export default ServicesBanner;
