import React from "react";
import bulletpoint from "../../assets/blobs/bulletpoint.svg";

const History = () => {
  return (
    <div className="w-7/12 py-20">
      <h2 className="text-4xl mb-12 text-custom-grey">Company History</h2>
      <div className="relative m-8">
        <div className="border-r-2 border-dashed border-custom-pink absolute h-full left-10"></div>
        <ul className="list-none m-0 p-0">
          <li className="mb-2">
            <div className="flex items-center mb-1">
              <img src={bulletpoint} className="h-20 w-20 z-10" />
              <h3 className="text-custom-pink text-3xl mb-8 ml-4">
                2010<br></br>Inception and Vision
              </h3>
            </div>
            <p className="mb-8 ml-24">
              Founded with a clear vision: to provide exceptional backbone
              infrastructure services that would set new standards in
              reliability, innovation, and global connectivity. In our early
              years, we focused on building a strong foundation of cutting-edge
              technologies and assembling a team of experts dedicated to shaping
              the future of connectivity.
            </p>
          </li>
          <li className="mb-2">
            <div className="flex items-center mb-1">
              <img src={bulletpoint} className="h-20 w-20 z-10" />
              <h3 className="text-custom-pink text-3xl mb-8 ml-4">
                2014<br></br>Global Expansion
              </h3>
            </div>
            <p className="mb-8 ml-24">
              Recognising the growing need for global connectivity, GB Wholesale
              expanded its reach by establishing strategic partnerships
              and enhancing its network infrastructure. This expansion marked a
              pivotal moment in our journey, allowing us to offer unparalleled
              connectivity solutions to organisations around the world.
            </p>
          </li>
          <li className="mb-2">
            <div className="flex items-center mb-1">
              <img src={bulletpoint} className="h-20 w-20 z-10" />
              <h3 className="text-custom-pink text-3xl mb-8 ml-4">
                2018<br></br>Launch of LeaseConnect Services
              </h3>
            </div>
            <p className="mb-8 ml-24">
              In response to the changing landscape of organisational networking
              needs, GB Wholesale introduced LeaseConnect services. This
              innovative offering provided organisations with the flexibility to
              lease IP addresses, enabling them to optimise their network
              management and scale with ease.
            </p>
          </li>
          <li className="mb-2">
            <div className="flex items-center mb-1">
              <img src={bulletpoint} className="h-20 w-20 z-10" />
              <h3 className="text-custom-pink text-3xl mb-8 ml-4">
                2022<br></br>Technological Advancements
              </h3>
            </div>
            <p className="ml-24">
              GB Wholesale continued to invest in the latest technological
              advancements, ensuring that our clients always had access to
              state-of-the-art solutions. From the implementation of high-speed
              data transfer technologies to anticipating the rise of emerging
              networking trends, we remained committed to staying ahead in the
              ever-evolving digital domain.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default History;
