import React from "react";
import image1 from "../../assets/images/laptop.png";
import image2 from "../../assets/images/networkz.png";
import image3 from "../../assets/images/datacables.png";

const Services = () => {
  return (
    <div className="w-7/12">
      <div className="flex">
        <div className="w-2/3 mr-8">
          <h2 className="text-3xl text-custom-grey mb-10">
            Core Network Solutions
          </h2>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink  mb-2">
              Cutting-edge Backbone Infrastructure:
            </h3>
            <p>
              Our Core Network Solutions form the bedrock of reliable and
              high-performance connectivity. We pride ourselves on deploying
              cutting-edge backbone infrastructure, ensuring that your
              organisation operates seamlessly without interruptions. Experience
              the power of a network designed to exceed expectations.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink  mb-2">
              Seamless Data Transmission for Enhanced Performance:
            </h3>
            <p>
              Say goodbye to data bottlenecks. Our Core Network Solutions enable
              seamless data transmission, optimising performance and ensuring
              that your data flows effortlessly. Experience the efficiency of a
              network that adapts to your needs, providing the foundation for
              unparalleled digital experiences.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink  mb-2">
              Unrivaled Reliability to Keep You Connected:
            </h3>
            <p>
              In the digital age, reliability is non-negotiable. GB Wholesale
              guarantees unrivaled reliability, minimising downtime and keeping
              you connected when it matters most. Trust us to provide a network
              that acts as a dependable backbone for your organisation's
              success.
            </p>
          </div>
        </div>
        <div className="w-1/3">
          <img src={image1} className="w-auto h-auto mt-10" />
        </div>
      </div>
      <div className="flex flex-row-reverse mt-10">
        <div className="w-2/3 ml-8">
          <h2 className="text-3xl text-custom-grey mb-10 text-right">
            Global Connectivity
          </h2>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink mb-2 text-right">
              Extensive Reach Connecting Continents:
            </h3>
            <p className="text-right">
              We offer more than just connectivity; rather a bridge that spans
              continents. Our Global Connectivity solutions provide extensive
              reach, allowing you to connect with confidence and expand your
              operations globally. Break free from geographical constraints and
              embrace a network that knows no boundaries.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink mb-2 text-right">
              Low-latency, High-speed Data Transfer:
            </h3>
            <p className="text-right">
              In a world where speed is crucial, GB Wholesale delivers.
              Benefit from low-latency, high-speed data transfer, ensuring that
              your information reaches its destination swiftly and efficiently.
              With our Global Connectivity services, experience the thrill of
              instant communication, anytime, anywhere.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink mb-2 text-right">
              Connect with Confidence, Anytime, Anywhere:
            </h3>
            <p className="text-right">
              Our commitment to global connectivity means you can connect with
              confidence, knowing that we have your back. Whether you're
              establishing new partnerships or collaborating with international
              teams, our network ensures your connectivity is seamless,
              reliable, and secure.
            </p>
          </div>
        </div>
        <div className="w-1/3">
          <img src={image2} className="w-auto h-auto mt-10" />
        </div>
      </div>
      <div className="flex mt-10">
        <div className="w-2/3 mr-8">
          <h2 className="text-3xl text-custom-grey mb-10">
            LeaseConnect Services
          </h2>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink  mb-2">
              IP Address Leasing for Organisational Needs:
            </h3>
            <p>
              GB Wholesale introduces LeaseConnect Services, offering the
              flexibility to lease IP addresses tailored to your organisational
              needs. Whether you're a growing startup or an established
              enterprise, our IP address leasing solutions provide a
              customisable approach to network management.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink  mb-2">
              Tailored Solutions for Optimal Network Management:
            </h3>
            <p>
              No two organisations are alike, and neither are their networking
              requirements. With LeaseConnect Services, GB Wholesale provides
              tailored solutions for optimal network management. Choose the IP
              addresses that align with your unique needs, allowing you to scale
              your network effortlessly.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl text-custom-pink  mb-2">
              Secure and Scalable Connectivity Options:
            </h3>
            <p>
              Security is paramount in the digital landscape. GB Wholesale
              ensures secure and scalable connectivity options with LeaseConnect
              Services. Trust us to safeguard your data while providing the
              flexibility to scale your network infrastructure as your
              organisation grows.
            </p>
          </div>
        </div>
        <div className="w-1/3">
          <img src={image3} className="w-auto h-auto mt-10" />
        </div>
      </div>
    </div>
  );
};

export default Services;
