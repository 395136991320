import React from "react";

const Button = ({ text, onClick }) => {
  return (
    <button
      className="bg-white text-custom-grey font-semibold py-3 px-16 w-auto rounded-full text-lg hover:bg-slate-100"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
