import React from "react";

const Quote = () => {
  return (
    <div className="bg-custom-orange p-14 w-auto flex justify-center items-center">
      <h2 className="text-3xl lg:w-5/12 text-custom-grey text-center leading-[45px]">
        "GB Wholesale has transformed the way we connect globally. Their
        reliability and innovation have been crucial to our success in the
        digital era"
      </h2>
    </div>
  );
};

export default Quote;
