import React from "react";

const Card = ({ icon, title, description }) => {
  return (
    <div
      className={`w-64 text-center bg-custom-beige rounded-3xl p-2 pt-6 pb-6 shadow-lg shadow-[#FF00663B] grid items-start grid-rows-[1fr,1fr,2fr]`}
    >
      <div className="mt-4 bg-gradient-to-r from-custom-orange to-custom-pink icon-gradient">
        <i className="material-icons-round mt-2 mb-8 text-6xl">{icon}</i>
      </div>
      <h3 className="text-2xl text-custom-pink">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default Card;
