import React from "react";
import AboutBanner from "../components/about/AboutBanner";
import History from "../components/about/History";
import AboutCard from "../components/about/AboutCard";
import ButtonCard from "../components/common/ButtonCard";

const About = () => {
  return (
    <div>
      <header className="h-[926px]">
        <AboutBanner />
      </header>
      <section className="bg-custom-beige flex justify-center">
        <History />
      </section>
      <section className="flex flex-row justify-center items-center space-x-8 pt-20">
        <AboutCard
          icon="gpp_good"
          title="Reliability"
          description="We believe in the power of consistent, reliable connectivity as the backbone of successful organisations. Our commitment to reliability ensures that our clients can depend on us for uninterrupted and high-performance networking solutions."
        />
        <AboutCard
          icon="tips_and_updates"
          title="Innovation"
          description="Embracing innovation is at the core of our identity. We strive to deliver forward-thinking solutions that not only meet the current needs of our clients but also anticipate and adapt to future technological trends."
        />
        <AboutCard
          icon="language"
          title="Global Connectivity, local commitment"
          description="We understand the importance of a global reach paired with personalised service. Our commitment to global connectivity is matched by our dedication to providing localised, attentive support tailored to the unique needs of our clients."
        />
        <AboutCard
          icon="thumb_up"
          title="Transformation and Empowerment"
          description="We believe in transforming connectivity into a powerful tool for empowerment. By empowering organisations with seamless networking solutions, we contribute to their success in the digital era."
        />
      </section>
      <section className="flex justify-center items-center pt-20 pb-20">
        <ButtonCard
          title="Join us on the Journey"
          description="As we continue to evolve and innovate, we remain steadfast in our commitment to being the backbone of your seamless global connections."
          buttonText="Click here"
        ></ButtonCard>
      </section>
    </div>
  );
};

export default About;
