import React, { useState } from "react";
import Button from "../common/GradientButton";
import axios from "axios";
import { validateForm } from "../../utils/formValidation";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { isValid, errors } = validateForm(name, email, subject, message);
    setErrors(errors);
    if (!isValid) return;
    const formData = { name, email, subject, message };
    try {
      await axios.post("/api/send-email", formData);
      window.location.reload();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit} noValidate>
      <div className="flex">
        <div className="flex flex-col mr-8">
          <input
            className="w-full border border-custom-pink text-black bg-white px-3 py-2 rounded placeholder-custom-grey focus:outline-custom-pink"
            type="text"
            placeholder="Your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && (
            <p className="text-custom-pink mt-4">{errors.name}</p>
          )}
        </div>
        <div className="flex flex-col">
          <input
            className="w-full border border-custom-pink text-black bg-white px-3 py-2 rounded placeholder-custom-grey focus:outline-custom-pink"
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && (
            <p className="text-custom-pink mt-4">{errors.email}</p>
          )}
        </div>
      </div>
      <input
        className="border border-custom-pink text-black bg-white px-3 py-2 rounded placeholder-custom-grey focus:outline-custom-pink"
        type="text"
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      {errors.subject && <p className="text-custom-pink">{errors.subject}</p>}
      <textarea
        className="border border-custom-pink text-black bg-white px-3 py-2 rounded placeholder-custom-grey focus:outline-custom-pink h-48 resize-none"
        placeholder="Leave message here"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      {errors.message && (
        <p className="text-custom-pink mb-4">{errors.message}</p>
      )}
      <Button type="submit" text="Send Message" />
    </form>
  );
};

export default Form;
