import React from "react";
import ServicesBanner from "../components/services/ServicesBanner";
import Service from "../components/services/Services";
import ButtonCard from "../components/common/ButtonCard";

const Services = () => {
  return (
    <div>
      <header className="h-[926px]">
        <ServicesBanner />
      </header>
      <section className="bg-custom-beige flex justify-center items-center pt-20 pb-20">
        <Service></Service>
      </section>
      <section className="flex justify-center items-center pt-20 pb-20">
        <ButtonCard
          title="Join us on the Journey"
          description="As we continue to evolve and innovate, we remain steadfast in our commitment to being the backbone of your seamless global connections."
          buttonText="Click here"
        ></ButtonCard>
      </section>
    </div>
  );
};

export default Services;
