import React from "react";
import smallBlob1 from "../../assets/blobs/smallblob1.svg";
import smallBlob2 from "../../assets/blobs/smallblob2.svg";
import smallBlob3 from "../../assets/blobs/smallblob3.svg";

const Card = ({ icon, title, description, blob }) => {
  let logo;

  if (blob === "smallBlob1") {
    logo = smallBlob1;
  } else if (blob === "smallBlob2") {
    logo = smallBlob2;
  } else if (blob === "smallBlob3") {
    logo = smallBlob3;
  }

  return (
    <div
      className={`h-[500px] w-[300px] text-center bg-white rounded-3xl p-2 pt-6 pb-6 shadow-lg shadow-[#FF00663B] grid items-start overflow-auto`}
    >
      <div className="mt-4 relative">
        <img
          src={logo}
          className="absolute w-36 h-36 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
        />
        <i
          className={`material-icons-round  text-white text-6xl mt-4 relative`}
        >
          {icon}
        </i>
      </div>
      <h3 className="text-3xl text-custom-pink mt-10 mb-6">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default Card;
