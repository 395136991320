import React from "react";
import image1 from "../../assets/images/datacentre.png";
import image2 from "../../assets/images/callcentre.png";
import blob from "../../assets/blobs/bigblob1.svg";
import Button from "../common/GradientButton";
import { useContactNavigation } from "../../utils/contactNavigation";

const TransformingConnectivity = () => {
  return (
    <div className="flex flex-row-reverse h-[800px] justify-end overflow-hidden">
      <div className="w-1/3 flex flex-col justify-center ml-10">
        <h2 className="text-5xl text-custom-grey mb-10">
          Transforming Connectivity, Empowering Success
        </h2>
        <h3 className="text-4xl text-custom-pink mb-10">
          Unleash the Power of Seamless Networking
        </h3>
        <p className="mb-10">
          We are committed to revolutionising the way you connect. By choosing
          GB Wholesale, you're not just investing in a service; you're
          unlocking the potential for unparalleled success in the digital
          landscape.
        </p>
        <Button text="Click Here" onClick={useContactNavigation()} />
      </div>
      <div className="w-1/2 flex flex-col justify-center relative">
        <img
          src={blob}
          className=" absolute -translate-x-96 w-[150%] max-w-[150%]"
        />
        <img
          src={image1}
          className="absolute translate-x-36 -translate-y-44 w-5/12"
        />
        <img
          src={image2}
          className="absolute translate-x-96 translate-y-16 w-5/12"
        />
      </div>
    </div>
  );
};

export default TransformingConnectivity;
