import React from "react";

const ContactInfo = () => {
  return (
    <div>
      <h2 className="text-3xl mb-12">Contact Information</h2>
      <h3 className="text-xl text-custom-pink mt-8 mb-2">Address:</h3>
      <p>
        182-184 High Street North,<br></br>London, E6 2JA
      </p>
      <h3 className="text-xl text-custom-pink mt-8 mb-2">Phone:</h3>
      <p>+44 0207 175 5780</p>
      <h3 className="text-xl text-custom-pink mt-8 mb-2">Email:</h3>
      <p>info@gb-wholesale.com</p>
      <h3 className="text-xl text-custom-pink mt-8 mb-2">Office Hours:</h3>
      <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
    </div>
  );
};

export default ContactInfo;
