import React from "react";

const Button = ({ text, onClick }) => {
  return (
    <button
      className="bg-gradient-to-r from-custom-orange to-custom-pink text-white font-semibold py-4 px-0 w-1/2 rounded-full text-lg hover:from-10%"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
