import React from "react";
import Button from "./Button";
import { useContactNavigation } from "../../utils/contactNavigation";

const ButtonCard = ({ title, description, buttonText }) => {
  return (
    <div className="rounded-4xl shadow-md py-10 px-20 flex w-7/12 items-center bg-polygon bg-cover">
      <div className="flex-1 mr-16">
        <h3 className="text-5xl text-white mb-12">{title}</h3>
        <p className="text-white text-lg w-5/6">{description}</p>
      </div>
      <Button text={buttonText} onClick={useContactNavigation()} />
    </div>
  );
};

export default ButtonCard;
