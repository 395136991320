import React from "react";
import logo from "../../assets/blobs/logo.svg";

const Nav = () => {
  return (
    <nav className="relative flex items-center justify-between bg-custom-beige py-8 px-6 lg:px-32 2xl:px-96 border-b border-custom-pink">
      <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-14">
        <a href="/">
          <img
            src={logo}
            alt="Logo"
            className="h-24 w-auto transform scale-x-[-1] -rotate-[60deg]"
          />
          <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white">
            GB
          </h1>
        </a>
      </div>
      <div className="absolute right-80 space-x-6 md:space-x-10">
        <a
            href="/"
            className="text-custom-pink hover:text-black font-roboto font-medium"
        >
          Home
        </a>
        <a
            href="/about"
            className="text-custom-pink hover:text-black font-roboto font-medium"
        >
          About
        </a>
        <a
            href="/services"
            className="text-custom-pink hover:text-black font-roboto font-medium"
        >
          Services
        </a>
        <a
            href="/contact"
            className="text-custom-pink hover:text-black font-roboto font-medium"
        >
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Nav;
