import React from "react";
import HomeBanner from "../components/home/HomeBanner";
import Info from "../components/home/Info";
import Quote from "../components/home/Quote";
import HomeCard from "../components/home/HomeCard";
import TransformingConnectivity from "../components/home/TransformingConnectivity";
import ButtonCard from "../components/common/ButtonCard";

const Contact = () => {
  return (
    <div>
      <header className="h-[926px]">
        <HomeBanner />
      </header>
      <section className="flex justify-center items-center pt-20">
        <Info></Info>
      </section>
      <section className="pt-20">
        <Quote> </Quote>
      </section>
      <section className="flex justify-center items-center bg-custom-beige space-x-8 pt-20 pb-20">
        <HomeCard
          blob="smallBlob1"
          icon="gpp_good"
          title="Exceptional Reliability"
          description="At GB Wholesale, unwavering reliability isn't just a perk, it's woven into our service's DNA. Our advanced backbone keeps your business running like clockwork, with redundancy shielding you from downtime."
        />
        <HomeCard
          blob="smallBlob3"
          icon="tips_and_updates"
          title="Innovative Solutions for Tomorrow"
          description="We invest in the latest technologies, from high-speed data transfer to forward-thinking networking protocols. Our commitment to continuous innovation means your needs are anticipated and exceeded."
        />
        <HomeCard
          blob="smallBlob2"
          icon="language"
          title="Global Connectivity with a local touch"
          description="Benefit from our extensive network that spans continents with the attentive service of a local provider. We understand the importance of tailored solutions, meeting all your unique connectivity requirements."
        />
      </section>
      <section>
        <TransformingConnectivity></TransformingConnectivity>
      </section>
      <section className="flex justify-center items-center pt-20 pb-20">
        <ButtonCard
          title="How can we help you?"
          description="Our representatives are waiting to help you and your business grow, get in touch with one of our dedicated team today."
          buttonText="Click here"
        ></ButtonCard>
      </section>
    </div>
  );
};

export default Contact;
