import React from "react";
import image1 from "../../assets/images/chip.png";
import image2 from "../../assets/images/nobsaroundpc.png";
import blob1 from "../../assets/blobs/bannerblob1.svg";
import blob2 from "../../assets/blobs/bannerblob2.svg";
import blob3 from "../../assets/blobs/bannerblob3.svg";

const AboutBanner = () => {
  return (
    <div className="flex h-[850px] justify-center overflow-hidden">
      <div className="w-1/5 flex justify-center">
        <img
          src={blob3}
          className="transform rotate-180 -translate-x-1/2 -translate-y-1/4"
        />
      </div>
      <div className="w-1/3 flex flex-col justify-center mr-20">
        <h2 className="text-3xl text-custom-pink mb-6">
          About GB Wholesale
        </h2>
        <h1 className="text-8xl text-custom-grey mb-20">Our story</h1>
        <p>
          At GB Wholesale, our journey began with a vision to redefine
          connectivity, empowering businesses to thrive in the digital age.
          Established in 2010 by a team of seasoned professionals with a passion
          for innovation, GB Wholesale set out to create a backbone
          infrastructure that would not just meet but exceed the evolving
          demands of the tech-driven world.<br></br>
          <br></br> Our founders, each bringing a wealth of experience from
          leading technology and telecommunications companies, shared a common
          belief in the transformative power of robust, reliable, and innovative
          networking solutions. Inspired by the rapidly advancing digital
          landscape, they embarked on a mission to build a company that would
          serve as the backbone of seamless, global connectivity.
        </p>
      </div>
      <div className="flex-1 flex flex-col justify-center relative">
        <img
          src={blob1}
          className="transform rotate-[200deg] absolute -translate-y-28 translate-x-36 w-4/6"
        />
        <img src={blob2} className="translate-x-36 w-full" />
        <img src={image2} className="absolute -translate-y-28 w-5/12" />
        <img
          src={image1}
          className="absolute translate-x-36 translate-y-36 w-5/12"
        />
      </div>
    </div>
  );
};

export default AboutBanner;
