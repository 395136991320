import React from "react";

const Info = () => {
  return (
    <div className="flex justify-between w-7/12">
      <div className="w-1/2 mr-4">
        <h2 className="text-4xl mb-6 text-custom-grey">
          Choose GB Wholesale
        </h2>
        <h3 className="text-3xl w-2/3 mb-6 text-custom-pink">
          Unmatched reliability and continuous innovation.
        </h3>
        <p>
          Unbreakable foundation, boundless innovation.<br></br> <br></br>
          GB Wholesale is your bedrock in the digital storm. We deliver
          unwavering reliability, ensuring consistent connections even under
          pressure. Our network is your launchpad, built with cutting-edge
          technology to propel you forward. We invest in tomorrow, so you can
          conquer it today. <br></br>
          <br></br> Choose GB Wholesale, choose digital dominance. <br></br>
          <br></br>Beyond mere stability, we champion ongoing innovation,
          adapting to emerging trends and leading the way in technological
          solutions.
        </p>
      </div>
      <div className="w-1/2">
        <div className="bg-custom-pink rounded-3xl p-6">
          <div className="flex items-center mb-4 text-white">
            <div className="bg-white rounded-full p-4 pt-4 pb-2 mr-6">
              <i className="material-icons-round bg-gradient-to-r from-custom-orange to-custom-pink icon-gradient-large text-5xl">
                schema
              </i>
            </div>
            <div>
              <h3 className="text-3xl mb-4">Network Solutions</h3>
              <p>
                Cutting-edge backbone infrastructure. Streamlined data with
                boosted performance. Unrivaled reliability to keep you
                connected.
              </p>
            </div>
          </div>
          <div className="flex items-center mb-4 text-white">
            <div className="bg-white rounded-full p-4 pt-4 pb-2 mr-6">
              <i className="material-icons-round bg-gradient-to-r from-custom-orange to-custom-pink icon-gradient-large text-5xl">
                public
              </i>
            </div>
            <div>
              <h3 className="text-3xl mb-4">Global Connectivity</h3>
              <p>
                Extensive reach connecting continents. Low-latency, high-speed
                data transfer. Connect with confidence, anytime, anywhere.
              </p>
            </div>
          </div>
          <div className="flex items-center mb-4 text-white">
            <div className="bg-white rounded-full p-4 pt-4 pb-2 mr-6">
              <i className="material-icons-round bg-gradient-to-r from-custom-orange to-custom-pink icon-gradient-large text-5xl">
                settings_input_component
              </i>
            </div>
            <div>
              <h3 className="text-3xl mb-4">LeaseConnect Services</h3>
              <p>
                IP address leasing for organisational needs. Tailored solutions
                for optimal network management. Secure and scalable connectivity
                options.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
